/* Navbar.css */
:root {
  --primary: #FF5C00;
  --primary-dark: #E05200;
  --primary-light: #FF7C33;
  --text-dark: #333333;
  --text-white: #FFFFFF;
  --navbar-bg: rgba(255, 255, 255, 0.95);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background-color: var(--navbar-bg);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  width: 90%;
  top: 0;
  left: 10%;
  padding-left: 5%;
  padding-right: 5%;
  z-index: 1000;
  transition: top 0.3s ease-in-out;
}

.nav-container {
  display: flex;
  align-items: center;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-link {
  position: relative;
  margin-left: 2rem;
}

.nav-link a {
  color: var(--text-dark);
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  text-transform: lowercase;
  letter-spacing: 0.03em;
  transition: color 0.3s ease;
  position: relative;
  padding: 0.5rem 0.5rem;
  display: inline-block;
}

.link-underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--primary);
  transition: width 0.3s ease;
}

.nav-link a:hover {
  color: var(--primary);
}

.nav-link a:hover .link-underline {
  width: 100%;
}

.active-nav-link {
  color: var(--primary) !important;
}

.active-nav-link .link-underline {
  width: 100%;
}

.nav-cta {
  display: inline-block;
  padding: 0.6rem 1.4rem;
  background-color: var(--primary);
  color: var(--text-white) !important;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  text-transform: none !important;
  letter-spacing: 0.03em;
  transition: all 0.3s ease;
}

.nav-cta:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(255, 92, 0, 0.25);
}

.mobile-menu-icon {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 28px;
  height: 20px;
  cursor: pointer;
}

.menu-line {
  width: 100%;
  height: 2px;
  background-color: var(--text-dark);
  transition: all 0.3s ease;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .mobile-menu-icon {
    display: flex;
  }
  
  .nav-links {
    position: absolute;
    top: 70px;
    left: 0;
    flex-direction: column;
    width: 100%;
    background-color: var(--navbar-bg);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 1rem 0;
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    align-items: flex-start;
  }
  
  .nav-links.open {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
  
  .nav-link {
    margin: 0.8rem 5%;
    width: 90%;
  }
  
  .nav-link a {
    display: block;
    width: 100%;
  }
  
  .cta-link {
    margin-top: 1rem;
  }
  
  .nav-cta {
    display: block;
    text-align: center;
    width: 100%;
  }
  
  /* Hamburger Menu Animation */
  .menu-line.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 6px);
  }
  
  .menu-line.open:nth-child(2) {
    opacity: 0;
  }
  
  .menu-line.open:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -6px);
  }
}

/* Services.css */
:root {
  --primary: #FF5C00;
  --primary-dark: #E05200;
  --primary-light: #FFEFE6;
  --text-dark: #333333;
  --text-light: #666666;
  --text-white: #FFFFFF;
  --bg-light: #FAFAFA;
}

.services-section {
  padding: 100px 0;
  background-color: var(--bg-light);
  min-height: 80vh;
  display: flex;
  align-items: center;
}

.section-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 5%;
}

.section-header {
  text-align: center;
  margin-bottom: 60px;
}

.section-title-container {
  display: inline-block;
  position: relative;
  margin-bottom: 15px;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text-dark);
  margin-bottom: 10px;
}

.title-underline {
  width: 70px;
  height: 3px;
  background-color: var(--primary);
  margin: 0 auto;
}

.section-subtitle {
  font-size: 1.1rem;
  color: var(--text-light);
  max-width: 600px;
  margin: 0 auto;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
}

.service-card {
  background-color: var(--text-white);
  border-radius: 8px;
  padding: 35px 25px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.04);
  transition: all 0.4s ease;
  position: relative;
  top: 0;
  opacity: 0;
  transform: translateY(30px);
  overflow: hidden;
}

.service-card:hover {
  transform: translateY(-10px) !important;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.service-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 0;
  background-color: var(--primary);
  transition: height 0.4s ease;
}

.service-card:hover::before {
  height: 100%;
}

.service-icon {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.service-title {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: var(--text-dark);
  transition: color 0.3s ease;
}

.service-card:hover .service-title {
  color: var(--primary);
}

.service-description {
  color: var(--text-light);
  line-height: 1.6;
  margin-bottom: 25px;
}

.service-link {
  display: inline-flex;
  align-items: center;
  color: var(--primary);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.service-link svg {
  margin-left: 6px;
  transition: transform 0.3s ease;
}

.service-link:hover {
  color: var(--primary-dark);
}

.service-link:hover svg {
  transform: translateX(4px);
}

/* Animation class that gets added via JavaScript */
.service-card.animate {
  opacity: 1;
  transform: translateY(0);
}

/* Stagger the animations */
.service-card:nth-child(1) {
  transition-delay: 0.1s;
}

.service-card:nth-child(2) {
  transition-delay: 0.2s;
}

.service-card:nth-child(3) {
  transition-delay: 0.3s;
}

.service-card:nth-child(4) {
  transition-delay: 0.4s;
}

.service-card:nth-child(5) {
  transition-delay: 0.5s;
}

.service-card:nth-child(6) {
  transition-delay: 0.6s;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .services-section {
    padding: 70px 0;
  }
  
  .section-title {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .services-grid {
    grid-template-columns: 1fr;
  }
  
  .section-title {
    font-size: 1.8rem;
  }
}

/* Hero.css */
:root {
  --primary: #FF5C00;
  --primary-dark: #E05200;
  --primary-light: #FFEFE6;
  --text-dark: #333333;
  --text-light: #666666;
  --text-white: #FFFFFF;
}

.hero-section {
  padding: 130px 0 80px;
  min-height: 80vh;
  display: flex;
  /* align-items: center; */
  position: relative;
  overflow: hidden;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  background-color: rgba(255, 92, 0, 0.03);
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.hero-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.hero-content {
  flex: 1;
  max-width: 600px;
  padding-right: 30px;
}

.hero-title {
  font-size: 3.2rem;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 25px;
  color: var(--text-dark);
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.6s ease;
}

.hero-title.animated {
  opacity: 1;
  transform: translateY(0);
}

.highlight {
  color: var(--primary);
  position: relative;
  display: inline-block;
}

.highlight::after {
  content: '';
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  height: 8px;
  background-color: rgba(255, 92, 0, 0.15);
  z-index: -1;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.4s 0.6s ease;
}

.hero-title.animated .highlight::after {
  transform: scaleX(1);
}

.hero-subtitle {
  font-size: 1.15rem;
  line-height: 1.6;
  color: var(--text-light);
  margin-bottom: 35px;
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.6s ease;
}

.hero-subtitle.animated {
  opacity: 1;
  transform: translateY(0);
}

.hero-cta {
  display: flex;
  gap: 15px;
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.6s ease;
}

.hero-cta.animated {
  opacity: 1;
  transform: translateY(0);
}

.primary-btn {
  display: inline-block;
  padding: 12px 24px;
  background-color: var(--primary);
  color: var(--text-white);
  text-decoration: none;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(255, 92, 0, 0.25);
}

.primary-btn:hover {
  background-color: var(--primary-dark);
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(255, 92, 0, 0.3);
}

.secondary-btn {
  display: inline-block;
  padding: 12px 24px;
  background-color: transparent;
  color: var(--primary);
  text-decoration: none;
  border-radius: 6px;
  font-weight: 500;
  border: 1px solid var(--primary);
  transition: all 0.3s ease;
}

.secondary-btn:hover {
  background-color: rgba(255, 92, 0, 0.05);
  transform: translateY(-3px);
}

.hero-image {
  flex: 1;
  max-width: 500px;
  position: relative;
  opacity: 0;
  transform: translateX(30px);
  transition: all 0.8s ease;
}

.hero-image.animated {
  opacity: 1;
  transform: translateX(0);
}

.image-shape {
  position: absolute;
  top: 10%;
  right: 10%;
  width: 80%;
  height: 80%;
  background-color: rgba(255, 92, 0, 0.1);
  border-radius: 20px;
  z-index: -1;
  transform: rotate(-8deg);
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.dashboard-mockup {
  width: 100%;
  height: 380px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  padding: 20px;
}

.dashboard-element {
  position: absolute;
  border-radius: 6px;
}

.element-1 {
  top: 20px;
  left: 20px;
  width: 70%;
  height: 40px;
  background: linear-gradient(to right, var(--primary-light), rgba(255, 255, 255, 0.7));
}

.element-2 {
  top: 80px;
  left: 20px;
  width: 40%;
  height: 120px;
  background: linear-gradient(45deg, rgba(255, 92, 0, 0.1), rgba(255, 92, 0, 0.05));
}

.element-3 {
  top: 80px;
  right: 20px;
  width: 50%;
  height: 120px;
  background: linear-gradient(to bottom, #f9f9f9, #f1f1f1);
}

.element-4 {
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
  height: 140px;
  background: linear-gradient(to right, #f5f5f5, white);
}

.floating-shape {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
}

.shape-1 {
  width: 60px;
  height: 60px;
  background-color: rgba(255, 92, 0, 0.2);
  top: -20px;
  right: 40px;
  animation: float 6s ease-in-out infinite;
}

.shape-2 {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 92, 0, 0.15);
  bottom: 30px;
  left: 10px;
  animation: float 8s ease-in-out infinite 1s;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Responsive Styles */
@media (max-width: 992px) {
  .hero-section {
    padding: 120px 0 60px;
  }
  
  .hero-container {
    flex-direction: column;
  }
  
  .hero-content {
    max-width: 100%;
    padding-right: 0;
    margin-bottom: 50px;
    text-align: center;
  }
  
  .hero-image {
    max-width: 80%;
  }
  
  .hero-cta {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }
  
  .hero-image {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 2rem;
  }
  
  .hero-cta {
    flex-direction: column;
  }
  
  .primary-btn, .secondary-btn {
    width: 100%;
    text-align: center;
  }
}
/* Logo sizing styles */
.logo {
  display: flex;
  align-items: center;
}

.logo img {
  max-height: 140px; /* Reduced from whatever the original size was */
  width: auto; /* Maintain aspect ratio */
}

/* For even smaller screens */
@media (max-width: 480px) {
  .logo img {
    max-height: 32px; /* Even smaller on mobile devices */
  }
}